@font-face {
    font-family: "VattenfallHallDisplay-Bold";
    src: url("/public/static/typefonts/VattenfallHall-Bold.eot");
    src: url("/public/static/typefonts/VattenfallHallDisplay-Bold.eot?#iefix") format("embedded-opentype"), url("/public/static/typefonts/VattenfallHallDisplay-Bold.woff2") format("woff2"), url("/public/static/typefonts/VattenfallHallDisplay-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: "Vattenfall Hall NORDx";
    src: url("/public/static/typefonts/VattenfallHall-Light.eot");
    src: url("/public/static/typefonts/VattenfallHall-Light.eot?#iefix") format("embedded-opentype"), url("/public/static/typefonts/VattenfallHall-Light.woff2") format("woff2"), url("/public/static/typefonts/VattenfallHall-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: "Vattenfall Hall NORDx";
    src: url("/public/static/typefonts/VattenfallHall-LightItalic.eot");
    src: url("/public/static/typefonts/VattenfallHall-LightItalic.eot?#iefix") format("embedded-opentype"), url("/public/static/typefonts/VattenfallHall-LightItalic.woff2") format("woff2"), url("/public/static/typefonts/VattenfallHall-LightItalic.woff") format("woff");
    font-weight: 300;
    font-style: italic;
  }
  @font-face {
    font-family: "Vattenfall Hall NORDx";
    src: url("/public/static/typefonts/VattenfallHall-Regular.eot");
    src: url("/public/static/typefonts/VattenfallHall-Regular.eot?#iefix") format("embedded-opentype"), url("/public/static/typefonts/VattenfallHall-Regular.woff2") format("woff2"), url("/public/static/typefonts/VattenfallHall-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: "VattenfallHall-Regular";
    src: url("/public/static/typefonts/VattenfallHall-Regular.eot");
    src: url("/public/static/typefonts/VattenfallHall-Regular.eot?#iefix") format("embedded-opentype"), url("/public/static/typefonts/VattenfallHall-Regular.woff2") format("woff2"), url("/public/static/typefonts/VattenfallHall-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Vattenfall Hall NORDx";
    src: url("/public/static/typefonts/VattenfallHall-RegularItalic.eot");
    src: url("/public/static/typefonts/VattenfallHall-RegularItalic.eot?#iefix") format("embedded-opentype"), url("/public/static/typefonts/VattenfallHall-RegularItalic.woff2") format("woff2"), url("/public/static/typefonts/VattenfallHall-RegularItalic.woff") format("woff");
    font-weight: 400;
    font-style: italic;
  }
  @font-face {
    font-family: "Vattenfall Hall NORDx";
    src: url("/public/static/typefonts/VattenfallHall-Medium.eot");
    src: url("/public/static/typefonts/VattenfallHall-Medium.eot?#iefix") format("embedded-opentype"), url("/public/static/typefonts/VattenfallHall-Medium.woff2") format("woff2"), url("/public/static/typefonts/VattenfallHall-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: "Vattenfall Hall NORDx";
    src: url("/public/static/typefonts/VattenfallHall-MediumItalic.eot");
    src: url("/public/static/typefonts/VattenfallHall-MediumItalic.eot?#iefix") format("embedded-opentype"), url("/public/static/typefonts/VattenfallHall-MediumItalic.woff2") format("woff2"), url("/public/static/typefonts/VattenfallHall-MediumItalic.woff") format("woff");
    font-weight: 500;
    font-style: italic;
  }
  @font-face {
    font-family: "Vattenfall Hall Display NORDx";
    src: url("/public/static/typefonts/VattenfallHallDisplay-Bold.eot");
    src: url("/public/static/typefonts/VattenfallHallDisplay-Bold.eot?#iefix") format("embedded-opentype"), url("/public/static/typefonts/VattenfallHallDisplay-Bold.woff2") format("woff2"), url("/public/static/typefonts/VattenfallHallDisplay-Bold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: "Vattenfall Hall NORDx";
    src: url("/public/static/typefonts/VattenfallHallDisplay-Bold.eot");
    src: url("/public/static/typefonts/VattenfallHallDisplay-Bold.eot?#iefix") format("embedded-opentype"), url("/public/static/typefonts/VattenfallHallDisplay-Bold.woff2") format("woff2"), url("/public/static/typefonts/VattenfallHallDisplay-Bold.woff") format("woff");
    font-weight: 800;
    font-style: normal;
  }
  @font-face {
    font-family: "Vattenfall Hall Bold NORDx";
    src: url("/public/static/typefonts/VattenfallHall-Bold.eot");
    src: url("/public/static/typefonts/VattenfallHall-Bold.eot?#iefix") format("embedded-opentype"), url("/public/static/typefonts/VattenfallHall-Bold.woff2") format("woff2"), url("/public/static/typefonts/VattenfallHall-Bold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: "Vattenfall Hall NORDx";
    src: url("/public/static/typefonts/VattenfallHall-Bold.eot");
    src: url("/public/static/typefonts/VattenfallHall-Bold.eot?#iefix") format("embedded-opentype"), url("/public/static/typefonts/VattenfallHall-Bold.woff2") format("woff2"), url("/public/static/typefonts/VattenfallHall-Bold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: "Vattenfall Hall NORDx";
    src: url("/public/static/typefonts/VattenfallHall-BoldItalic.eot");
    src: url("/public/static/typefonts/VattenfallHall-BoldItalic.eot?#iefix") format("embedded-opentype"), url("/public/static/typefonts/VattenfallHall-BoldItalic.woff2") format("woff2"), url("/public/static/typefonts/VattenfallHall-BoldItalic.woff") format("woff");
    font-weight: 600;
    font-style: italic;
  }
  @font-face {
    font-family: "Vattenfall Hall NORDx";
    src: url("/public/static/typefonts/VattenfallHall-Heavy.eot");
    src: url("/public/static/typefonts/VattenfallHall-Heavy.eot?#iefix") format("embedded-opentype"), url("/public/static/typefonts/VattenfallHall-Heavy.woff2") format("woff2"), url("/public/static/typefonts/VattenfallHall-Heavy.woff") format("woff");
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: "Vattenfall Hall NORDx";
    src: url("/public/static/typefonts/VattenfallHall-HeavyItalic.eot");
    src: url("/public/static/typefonts/VattenfallHall-HeavyItalic.eot?#iefix") format("embedded-opentype"), url("/public/static/typefonts/VattenfallHall-HeavyItalic.woff2") format("woff2"), url("/public/static/typefonts/VattenfallHall-HeavyItalic.woff") format("woff");
    font-weight: 700;
    font-style: italic;
  }
  @font-face {
    font-family: "vf-icons";
    src: url("/public/static/typefonts/vf-icons.woff") format("woff"), url("/public/static/typefonts/vf-icons.svg#vf-icons") format("svg");
    font-weight: normal;
    font-style: normal;
  }
  